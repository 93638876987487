import { FVY_DOMAIN, IR_DOMAIN, SITE } from "@/config/constants";
import { HAIR_GROWTH_PATH } from "@/config/site/favy";
import { getVertical } from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import tw from "twin.macro";

import { IR_LIFE_INSURANCE_DEFAULT_PATH } from "../../../config/site/insuranceranked";
import Filters from "./Filters";

const styles = {
	wrapper: {
		baseStyles: {
			[IR_DOMAIN]: css`
				@media (max-width: 991px) {
					background-color: #f5f8fa;
				}
			`,
			[FVY_DOMAIN]: tw`bg-fvy-purple mx-auto pb-4`,
		},
		verticalOverrides: {
			[IR_DOMAIN]: {
				[IR_LIFE_INSURANCE_DEFAULT_PATH]: tw`bg-[#F5F8FA]`,
			},
			[FVY_DOMAIN]: {
				[HAIR_GROWTH_PATH]: tw`mx-auto max-w-[550px] sm:min-w-[582px] lg:max-w-none lg2:max-w-[1170px] bg-transparent px-4 xl:px-0`,
			},
		},
	},
	container: {
		baseStyles: {
			[IR_DOMAIN]: css`
				margin-top: 10px;
				display: flex;
				align-items: center;
				background-clip: content-box;
				background-color: #f5f8fa;

				@media (max-width: 1199px) {
					padding: 0 30px;
				}

				@media (max-width: 991px) {
					margin-top: 0;
				}

				@media (max-width: 767px) {
					max-width: 500px;
					margin-left: auto;
					margin-right: auto;
					padding: 0;
				}

				> div {
					margin: 7px 10px 7px 10px !important;
				}
			`,
		},
		verticalOverrides: {
			[IR_DOMAIN]: {
				[IR_LIFE_INSURANCE_DEFAULT_PATH]: css`
					margin: 0;
					margin-left: auto;
					margin-right: auto;
					display: block;
					border: unset;
					box-shadow: unset;
					border-radius: unset;
					background-color: transparent;
					background-clip: unset;

					@media (min-width: 1200px) {
						padding: 14px 15px !important;
					}

					@media (max-width: 1199px) {
						padding: 14px 15px;
					}

					@media (max-width: 767px) {
						max-width: unset;
						padding-left: 15px;
						padding-right: 15px;
					}

					@media (max-width: 639px) {
						padding-top: 16px;
						padding-bottom: 16px;
					}

					> div {
						margin: 0 !important;
					}
				`,
			},
			[FVY_DOMAIN]: {
				[HAIR_GROWTH_PATH]: tw`h-[102px] md:h-[64px] bg-white max-w-full mx-4 py-3 px-4 mb-4 shadow-sm rounded-lg`,
			},
		},
	},
};

const FixedTopFilters = ({ buildFilteringState, isNotUserFiltered }) => {
	const vertical = useSelector(getVertical);
	return (
		<div
			css={[
				path(["wrapper", "baseStyles", SITE], styles),
				path(["wrapper", "verticalOverrides", SITE, vertical], styles),
			]}
		>
			<div
				className="container"
				css={[
					path(["container", "baseStyles", SITE], styles),
					path(["container", "verticalOverrides", SITE, vertical], styles),
				]}
			>
				<Filters
					isMobile={false}
					buildFilteringState={buildFilteringState}
					disabled={isNotUserFiltered}
				/>
			</div>
		</div>
	);
};

export default FixedTopFilters;

FixedTopFilters.propTypes = {
	buildFilteringState: PropTypes.func.isRequired,
	isNotUserFiltered: PropTypes.bool.isRequired,
	isMobile: PropTypes.bool,
};
